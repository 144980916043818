
.messagesWrapper {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: stretch;
    gap: 10px;
    border: 1px gainsboro solid;
    /* height: 100px; */
    /* overflow: scroll; */
    margin-top: 1rem;
  }